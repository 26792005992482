import React, { useRef } from 'react';
import { Button, Drawer, Form, FormsOnSubmit, HorizontalGroup } from '@grafana/ui';
import FunctionForm, { FunctionFormValues } from './FunctionForm';
import { POST } from '../../client';
import useConfirm from '../../hooks/useConfirm';
import UpdateIsDirty from '../utils/UpdateIsDirty';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

interface FunctionAddDrawerProps {
  onClose: () => void;
}

const FunctionAddDrawer: React.FC<FunctionAddDrawerProps> = ({ onClose }) => {
  const [ConfirmModalComponent, showConfirm] = useConfirm({
    title: 'Unsaved Changes',
    body: 'You are in the middle of adding a function, are you sure you want to discard it?',
    confirmText: 'Discard',
    onConfirm: onClose,
  });
  const isDirtyRef = useRef(false);

  const handleClose = () => {
    if (isDirtyRef.current) {
      showConfirm();
      return;
    }
    onClose();
  };

  const handleSubmit: FormsOnSubmit<FunctionFormValues> = async (data) => {
    try {
      if (data.pinLevel === 'SYSTEM') {
        return;
      }
      const path =
        data.type === 'Source'
          ? ('source' as const)
          : data.type === 'Process'
          ? ('process' as const)
          : data.type === 'Sink'
          ? ('sink' as const)
          : ('import' as const);
      const { error } = await POST(`/api/${path}-functions` as const, {
        body: {
          inputType: data.inputType,
          outputType: data.outputType,
          function: {
            name: data.name,
            description: data.description,
            schema: JSON.parse(data.schema),
            code: data.code,
            pinLevel: data.pinLevel,
          },
        },
      });
      const appEvents = getAppEvents();
      if (!error) {
        appEvents.publish({
          type: AppEvents.alertSuccess.name,
          payload: ['Function added successfully'],
        });
        onClose();
      } else {
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Function added failed: ${error?.message ?? 'Server unreachable'}`],
        });
      }
    } catch (err) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`Function added failed: ${err}`],
      });
    }
  };

  return (
    <Drawer title="Add Function" size="md" onClose={handleClose}>
      <>
        <Form
          onSubmit={handleSubmit}
          width="100%"
          maxWidth="none"
          className="pb-4"
          defaultValues={{
            type: 'Source',
            inputType: 'TIMESERIES',
            outputType: 'TIMESERIES',
            schema: JSON.stringify(
              {
                $schema: 'http://json-schema.org/draft-07/schema',
                properties: {
                  host: {
                    type: 'string',
                    pattern:
                      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
                  },
                  port: {
                    type: 'number',
                    minimum: 1024,
                    maximum: 65535,
                  },
                },
                required: ['host', 'port'],
              },
              null,
              4
            ),
            code: DEFAULT_CODE,
            pinLevel: 'UNPINNED',
          }}
        >
          {(props) => (
            <>
              <UpdateIsDirty isDirtyRef={isDirtyRef} isDirty={props.formState.isDirty} />
              <FunctionForm {...props} />
              <HorizontalGroup spacing="md">
                {/*<Button*/}
                {/*  type="button"*/}
                {/*  onClick={async () => {*/}
                {/*    await handleTest(props.getValues());*/}
                {/*  }}*/}
                {/*  variant="success"*/}
                {/*>*/}
                {/*  Test*/}
                {/*</Button>*/}
                <Button type="submit">Add</Button>
              </HorizontalGroup>
            </>
          )}
        </Form>
        {ConfirmModalComponent}
      </>
    </Drawer>
  );
};

const DEFAULT_CODE = `
## Source function ##

# from typing import Generator, Callable
# 
# def handle(args: dict, subscribe: Callable[[dict], Generator]) -> Generator:
#     # TODO: implement your function here
#     # yield data every time you want to send data to the next function
#     # e.g.
#     # while True:
#     #     data = get_data()
#     #     yield data

## Process function ##

# from typing import Iterable, Generator
#
# def handle(data: Iterable, args: dict) -> Generator:
#     # TODO: implement your function here
#     # loop over iterable to get data yield from previous function
#     # yield data every time you want to send data to the next function
#     # e.g.
#     # for d in data:
#     #     yield process(d)

## Sink function ##

# from typing import Iterable, Callable, List

# def handle(
#     data: Iterable,
#     args: dict,
#     register: Callable[[List[dict]], None],
#     send: Callable[[dict], None],
# ):
#     # TODO: implement your function here
#     # loop over iterable to get data yield from previous function
#     # call register() to register channels and upsert attributes
#     # call send() to send data to grafana
#     # e.g.
#     # register([{ 'name': 'ch1' }, { 'name': 'ch2', 'channel_attributes': {}, 'plotting_attributes': {}])
#     # import time
#     # for d in data:
#     #     send({
#     #         'time': [time.time()], # required, [float, float, ...] | np.ndarray[float]
#     #         'ch1': [d['ch1']], # [float, float, ...] | np.ndarray[float]
#     #         'ch2': [d['ch2']], # [float, float, ...] | np.ndarray[float]
#     #     })

# Import function ##

# def handle(
#     files: List[str],
#     args: dict,
#     register: Callable[[List[dict]], None],
#     send: Callable[[dict], None],
# ):
#     #registered_channels = {}
#     #for file in files:
#     #  dataset = pd.read_csv(file)
#     #  data = dataset.to_dict('list');
#     #  for key in data.keys():
#     #    if key not in registered_channels and key != 'time':
#     #      registered_channels[key] = { 'name': key }
#     #  register(list(registered_channels.values()))
#     #  send(data)
`.trim();

export default FunctionAddDrawer;
